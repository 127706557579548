import api from '../../axios'

export const dataPaymentCollection = (params) => {
  return new Promise((resolve, reject) => {
    api.get('/api/v1/invoice/payment/data-table',{
        params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
        }
    }).then((r) => {
      resolve(r)
    }).catch((e) => {
      reject(e)
    })
  })
}

export const collectCashLog = (params) => {
  return new Promise((resolve, reject) => {
    api.get('/api/v1/invoice/cash-log/data-table',{
        params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            status: params.status ? params.status : 0,
        }
    }).then((r) => {
      resolve(r)
    }).catch((e) => {
      reject(e)
    })
  })
}

export const collectCashLogLine = (params) => {
  return new Promise((resolve, reject) => {
    api.get('/api/v1/invoice/cash-log/data-table/' + params.ID,{
        params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
        }
    }).then((r) => {
      resolve(r)
    }).catch((e) => {
      reject(e)
    })
  })
}

export const changeStatusCashLog = (params) => {
  return new Promise((resolve, reject) => {
    api.post(`/api/v1/invoice/cash-log/change-status/${params.ID}/${params.status}`).then((r) => {
      resolve(r)
    }).catch((e) => {
      reject(e)
    })
  })
}